import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './App.css';

const App = () => {
  const iframeUrls = [
    'https://emex.hubsoft.com.br:8443/public/dashboard/f70f5caa-e78f-4b11-8837-f3fbe7a0ef21#refresh=60',
    // 'http://192.168.0.11',
    'https://emex.hubsoft.com.br:8443/public/dashboard/847a29b6-4fe9-4b11-abed-fdc02c7c4bb7#refresh=60',
    // 'http://192.168.0.12'
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const nextSlide = (currentSlide + 1) % iframeUrls.length;
    const delay = currentSlide === 0 ? 10000 : 10000;
    
    const timer = setTimeout(() => {
      setCurrentSlide(nextSlide);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [currentSlide]); // Removido iframeUrls.length das dependências

  return (
    <div className="carousel-container">
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        className="carousel"
        selectedItem={currentSlide}
        autoPlay={false}
        infiniteLoop={true}
      >
        {iframeUrls.map((url, index) => (
          <div key={index} className="slide">
            <iframe
              title={`iframe-${index}`}
              src={url}
              className="iframe"
            ></iframe>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default App;
